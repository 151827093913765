import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'
import SEO from 'src/components/seo'
import Layout from 'src/components/layout/layout'
import Row from 'src/components/core/row'
import Card from 'src/components/core/card'
import CardSlim from 'src/components/core/card-slim'
// import Flex from "src/components/core/flex"
import styled from 'styled-components'
import iconPhone from 'src/images/icons/phone.svg'
import iconMail from 'src/images/icons/mail.svg'
import iconTwitter from 'src/images/icons/twitter.svg'
import { Helmet } from 'react-helmet'

const ContactsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 2rem;
  @media (min-width: 800px) {
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: space-around;
  }
`

const ContactText = styled.div`
  @media (min-width: 576px) {
    padding: 0 2rem 2.5rem 0;
  }
  padding-bottom: 2.5rem;
  font-family: 'Old Standard TT', serif;
  font-family: 'Cormorant', serif;
`

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  /* justify-content: center; */
  @media (min-width: 800px) {
    margin-bottom: 0.2rem;
    justify-content: flex-start;
  }
`

const Contact = styled.span`
  height: 2rem;
  padding-left: 1rem;
  display: inline-block;
  font-family: 'Old Standard TT', serif;
  font-family: 'Cormorant', serif;
  line-height: 2rem;
  font-size: 1.5rem;
  @media (max-width: 320px) {
    font-size: 1.2rem;
  }
  @media (min-width: 800px) {
    font-size: 1.2rem;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`

const ContactIcon = styled.span`
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  flex-shrink: 0;
  img {
    height: 1.5rem;
    width: 1.5rem;
    margin: 0;
  }
  @media (min-width: 800px) {
    height: 1.2rem;
    width: 1.2rem;
    img {
      height: 1.2rem;
      width: 1.2rem;
      margin: 0;
    }
  }
`
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`
const ContactBg = styled.div`
  border-radius: 8px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      allFile(filter: { relativeDirectory: { eq: "contact" } }) {
        nodes {
          dir
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const contactPhoto = data.allFile.nodes[0].childImageSharp.fluid
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Martin Jennings - Contact</title>
        <meta
          name="description"
          content="Contact Martin Jennings. Small-scale bronze maquettes of the public sculptures are available to buy."
        />
      </Helmet>
      <Row>
        <Fade clear>
          <Card>
            <div>
              <ContactsWrapper>
                <ContactWrapper>
                  <ContactIcon>
                    <img src={iconMail} />
                  </ContactIcon>
                  <Contact>
                    <a href="mailto:mj@martinjennings.com">
                      mj@martinjennings.com
                    </a>
                  </Contact>
                </ContactWrapper>
                
              </ContactsWrapper>
              <ContactBg>
                <Img fluid={contactPhoto} />
              </ContactBg>
            </div>
          </Card>
        </Fade>
      </Row>
    </Layout>
  )
}

export default ContactPage
